<template>
    <div class="rounded-lg p-6 border rounded-lg flex-1">
        <div class="relative w-full">
            <div class="absolute">
                <!--rating !?? -->
            </div>
            <div class="absolute right-0 flex">
                <!--                <a class="block md:hidden mr-6" href="">-->
                <!--                    <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 20.981 26.525">-->
                <!--                        <g id="share-outline" transform="translate(1.25 1.25)" opacity="0.5">-->
                <!--                            <path id="Path_77" data-name="Path 77" d="M20.61,13.5h2.31a2.31,2.31,0,0,1,2.31,2.31V26.9a2.31,2.31,0,0,1-2.31,2.31H9.06A2.31,2.31,0,0,1,6.75,26.9V15.81A2.31,2.31,0,0,1,9.06,13.5h2.31" transform="translate(-6.75 -5.184)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />-->
                <!--                            <path id="Path_78" data-name="Path 78" d="M21.615,8,17,3.375,12.375,8" transform="translate(-7.755 -3.375)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />-->
                <!--                            <path id="Path_79" data-name="Path 79" d="M18,19.141V3.375" transform="translate(-8.76 -3.375)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />-->
                <!--                        </g>-->
                <!--                    </svg>-->
                <!--                </a>-->
                <svg width="20" height="20" viewBox="0 0 28.164 23.242" class="cursor-pointer">
                    <path
                        id="_23"
                        data-name="23"
                        d="M16.1,27.012a1.412,1.412,0,0,1-.833-.268L4.8,19.034a.734.734,0,0,1-.155-.141A8.833,8.833,0,0,1,16.1,5.507a8.839,8.839,0,0,1,11.522.861h0a8.853,8.853,0,0,1,0,12.525.735.735,0,0,1-.155.141L16.932,26.687A1.412,1.412,0,0,1,16.1,27.012ZM6.5,16.8l9.6,7.06,9.6-7.06a6.043,6.043,0,0,0-.071-8.472h0a6.029,6.029,0,0,0-8.472,0,1.412,1.412,0,0,1-2.005,0A6.056,6.056,0,0,0,6.5,16.8Z"
                        transform="translate(-2.052 -3.77)"
                        fill="#231f20"
                        opacity="0.5"
                    />
                    <path id="Path_50" data-name="Path 50" d="M2605.027,1375.483l3.363-2.4,5.89.491,4.247,2.575v4.546l-.806,4.555-4.806,4.015-7.888,2.158-4.967-3.347-4.8-6.758-.3-4.122,1.56-2.432,4.8-1.19Z" transform="translate(-2593 -1371.54)" fill="#231f20" opacity="0" />
                </svg>
            </div>
        </div>
        <div class="flex flex-wrap mt-2">
            <div class="w-full lg:w-1/5">
                <Link :href="`/urun/${product.default_url.slug}`">
                    <!--                    <img :src="product.media_storage[0]?.url" alt="" />-->
                    <picture class="flex justify-center">
                        <source :srcset="product.imagesOptimized[0]?.urlThumbWebP" type="image/webp" />
                        <source :srcset="product.imagesOptimized[0]?.urlThumbOrj" type="image/jpeg" />
                        <img :src="product.imagesOptimized[0]?.urlThumbOrj" alt="Alt Text!" />
                    </picture>
                </Link>
            </div>
            <div class="md:pl-4 w-full lg:w-4/5">
                <div class="text-sm font-semibold mt-3 min-h-[42px] max-h-[42px] overflow-y-hidden">
                    <Link :href="`/urun/${product.default_url.slug}`" :class="`short-description`">
                        {{ product.attribute_data.name.tr }}
                    </Link>
                </div>
                <div class="text-xs mt-1 text-kbgray min-h-[34px] max-h-[34px] overflow-y-hidden">
                    <Link :href="`/urun/${product.default_url.slug}`" :class="`short-description`">
                        {{ product.attribute_data.excerpt?.tr }}
                    </Link>
                </div>
                <div class="mt-3 max-w-smv-p-box lg:max-w-v-p-box">
                    <div class="flex text-xs text-center cursor-pointer">
                        <ul class="flex border rounded-full bg-white w-full items-center justify-center shadow-xl h-8 ts:h-12">
                            <template v-for="item in subscribetionMonthsOrdered">
                                <li class="text-textgray whitespace-nowrap w-16 h-14 text-xs hover:scale-125 transform duration-300 ease-in-out items-center justify-center flex" :class="[selectedMonth == item[1].subscription_months.id ? selectedClass : '']" @click="selectMonth(item[1].subscription_months.id)" v-if="item[1].price">
                                    {{ item[1].subscription_months?.name }}
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
                <div class="flex mt-2">
                    <span class="font-semibold text-xl mt-2"
                        ><Link :href="`/urun/${product.default_url.slug}`">{{ price }} TL /</Link></span
                    >
                    <span class="text-3xs self-center ml-2 font-santralregular"><Link :href="`/urun/${product.default_url.slug}`">Aylık ödenecek tutar</Link></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
    data() {
        return {
            selectedMonth: null,
            selectedClass: " shadow-inner shadow-sm border-2 h-14 md:h-14 ts:h-16 rounded-full border-kbgreen p-2 lg:p-4 items-center justify-center flex bg-white !text-black text-xs hover:!scale-100",
            price: 0,
            favouriteSate: false,
            subscribetionMonthsOrdered: [],
        };
    },
    components: {
        Link,
    },
    props: {
        product: Object,
    },
    methods: {
        selectMonth(month) {
            this.selectedMonth = month;
            this.price = this.product.variants.find((variant) => variant.id == this.selectedVariandId).prices.find((price) => price.subscription_months.id == month).price / 100;
            this.comparePrice = this.product.variants.find((variant) => variant.id == this.selectedVariandId).prices.find((price) => price.subscription_months.id == month).compare_price / 100;
            if (this.comparePrice > 0) this.discountRate = Math.round(100 - (100 * this.price) / this.comparePrice);
            if (this.comparePrice == 0) this.discountRate = null;
        },
        toogleFavourite() {
            // Kullanıcı login değil ise favori eklemek için login olmalı, yönlendir
            if (this.auth.token == null) {
                this.$inertia.get("/giris-yap");
            }

            window.axios.defaults.headers.common["Authorization"] = `Bearer ${this.auth.token}`;
            axios
                .post(`/favourite/${this.product.id}/toogle`, {
                    productSlug: this.product.default_url.slug,
                })
                .then((res) => {
                    if (res.data.status == "created") {
                        this.favouriteSate = true;
                    } else {
                        this.favouriteSate = false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {
        if (this.product?.variants[0]?.prices.length > 0) this.subscribetionMonthsOrdered = Object.entries(this.product?.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
        this.selectedVariandId = this.product.variants[0].id;
        let nonZeroPrices = this.product.variants[0].prices.filter((price) => price.price > 0);
        this.subscribetionMonthsOrdered = this.subscribetionMonthsOrdered.filter((price) => price[1].price > 0);
        this.price = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price / 100;
        this.comparePrice = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.compare_price / 100;
        if (this.comparePrice > 0) this.discountRate = Math.round(100 - (100 * this.price) / this.comparePrice);
        if (this.comparePrice == 0) this.discountRate = null;

        this.selectedMonth = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.subscription_months?.id;
        this.favouriteSate = this.product.favourite;
    },
};
</script>

<style scoped></style>
